import React, { useContext } from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import styled from '@emotion/styled/macro'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { CurrencyFormat } from '@paypalcorp/worldready'
import { Button as PPButton } from '@paypalcorp/pp-react'
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react'

import styledIcon from '../shared/icons'
import { AlertSvg } from '../shared/icons/svg-images'
import { ProductDiv } from '../shared/styled'
import { trackImpression } from '../../utils/analytics'
import getStoreErrorMsg from '../../graphql/getStoreErrorMsg'
import { Loader } from '../../components/loader'

const Content = styled.div`
  background-color: #ffffff;
  text-align: center;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

const Header = styled.div`
  font-size: 20px;
  color: #2c2e2f;
  padding-bottom: 24px;
`

const Text = styled.div`
  font-size: 18px;
  color: #2b2b2b;
  text-align: left;
  margin: 0 46px;
  padding-bottom: 36px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

const PromotionErrorText = styled.div`
  font-size: 20px;
  color: #2c2e2f;
  text-align: center;
  margin: 0 150px;
  padding-bottom: 36px;

  @media only screen and (max-width: 768px) {
    margin: 0 12px;
  }
`

const TxnErrorText = PromotionErrorText
const VelocityLimitText = PromotionErrorText
const PaymentErrorText = PromotionErrorText

const Button = styled(PPButton)`
  margin-bottom: 60px;
  width: 270px;

  @media only screen and (max-width: 768px) {
    width: 271px;
  }
`

const IconContainer = styled.div`
  padding-top: 103px;
  padding-bottom: 71px;
`

const AlertICON = styledIcon`
  & svg {
    height: 102px;
    width: 102px;
  }
`

AlertICON.defaultProps = { svg: AlertSvg }

const Error = ({
  history,
  data,
  title,
  routeTo,
  buttonText,
  promotionError = [],
  txnLimitErrors = [],
  velocityLimitErrors = [],
  paymentErrors = [],
}) => {
  const worldready = useContext(WorldReadyContext)

  trackImpression(
    'main:consumer:gifts:home:errorpage',
    'main:consumer:gifts:home:errorpage:::',
  )

  if (data.loading) {
    return <Loader py="10rem" />
  }
  const storeErrorMsg = lodash.get(data, 'getStoreInfo.store_error_msg')

  return (
    <div id="error-page">
      <ProductDiv>
        <Content id="content">
          <IconContainer id="icon">
            <AlertICON />
          </IconContainer>
          {title && <Header>{title}</Header>}
          {txnLimitErrors.length > 0 && (
            <div>
              {txnLimitErrors.map((err, i) => (
                <TxnErrorText key={`txn-limit-err-${i}`}>
                  <Message
                    id="pages/error.error.transactionLimitExceed.header"
                    amount={new CurrencyFormat(worldready, {
                      currency: err.currency || 'USD',
                      maximumFractionDigits: 0,
                    }).format(err.limit)}
                    productName={err.productName}
                  />
                </TxnErrorText>
              ))}
            </div>
          )}
          {velocityLimitErrors.length > 0 && (
            <div>
              {velocityLimitErrors.map((err, i) => (
                <VelocityLimitText key={`velocity-limit-err-${i}`}>
                  <Message
                    id="pages/error.error.perDayLimitExceed.header"
                    amount={new CurrencyFormat(worldready, {
                      currency: err.currency || 'USD',
                      maximumFractionDigits: 0,
                    }).format(err.limit)}
                    productName={err.productName}
                  />
                </VelocityLimitText>
              ))}
            </div>
          )}
          {promotionError.length > 0 && (
            <div>
              <PromotionErrorText>{promotionError[0]}</PromotionErrorText>
              <PromotionErrorText>{promotionError[1]}</PromotionErrorText>
            </div>
          )}
          {paymentErrors.length > 0 && (
            <div>
              <PaymentErrorText>{paymentErrors[0]}</PaymentErrorText>
            </div>
          )}
          {txnLimitErrors.length !== 0 &&
            promotionError.length !== 0 &&
            velocityLimitErrors.length !== 0 &&
            paymentErrors.length !== 0 && (
              <Text>
                {storeErrorMsg ? (
                  <div>{storeErrorMsg}</div>
                ) : (
                  <div>
                    <Message id="pages/error.error.text" />
                  </div>
                )}
                <div>
                  <Message id="pages/error.error.charges" />
                </div>
              </Text>
            )}
          <Button
            secondary
            data-testid="error-button"
            onClick={() => history.push(routeTo)}
          >
            {buttonText}
          </Button>
        </Content>
      </ProductDiv>
    </div>
  )
}

Error.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.object,
    getStoreErrorMsg: PropTypes.shape({
      store_error_msg: PropTypes.string,
    }),
  }),
  routeTo: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  promotionError: PropTypes.array,
  txnLimitErrors: PropTypes.array,
  velocityLimitErrors: PropTypes.array,
  paymentErrors: PropTypes.array,
}

const ErrorWithGraphql = lodash.flowRight(
  withApollo,
  graphql(getStoreErrorMsg),
)(Error)

export default ErrorWithGraphql
