import styled from '@emotion/styled/macro'
import { background, layout, position } from 'styled-system'
import { Box } from '../box'

export const Image = styled.img`
  transition: all 0.3s;
  opacity: 0;
  display: block;
  width: 100%;
  min-height: 1px;

  &.loaded {
    opacity: 1;
  }

  ${layout}
`

export const BgImageWrapper = styled(Box)`
  background-color: ${(props) => props.theme.colors.secondary.greylight};

  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @keyframes bg-scrolling-reverse {
    100% {
      background-position: 2662px 926px;
    }
  }

  // can override background properties
  ${background}
  ${position}

  &.loaded {
    opacity: 1;
  }
`
